<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"
        ><div class="ui-type-display-lg">
          {{ $t("user.profile.social.title") }}
        </div>
      </CCol>
      <CCol sm="auto">
        <CDropdown :togglerText="$t('user.profile.social.add_btn')" color="primary">
          <CDropdownItem @click.prevent="oauth2('facebook')"
            >Facebook</CDropdownItem
          >
          <CDropdownItem @click.prevent="oauth2('google')"
            >Google</CDropdownItem
          >
        </CDropdown>
      </CCol>
    </CRow>

    <CCard>
      <CCardBody>
        <CAlert v-if="error.hasGeneralErrors()" color="danger" class="mb-3">
          {{ error.general().join(" ") }}
        </CAlert>

        <ejs-grid
          :dataSource="dm"
          :allowPaging="false"
          :allowSorting="true"
          ref="grid"
        >
          <e-columns>
            <e-column headerText="Provider" field="provider"></e-column>
            <e-column
              :headerText="$t('models.social.last_login')"
              field="last_login"
              :template="lastLoginTemplate"
            ></e-column>
            <e-column
              :headerText="$t('models.social.date_joined')"
              field="date_joined"
              :template="dataJoinedTemplate"
            ></e-column>
            <e-column headerText="" :template="actionsTemplate"></e-column>
          </e-columns>
        </ejs-grid>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Vue from "vue";
import { Page, Sort } from "@syncfusion/ej2-vue-grids";
import EventBus from "../../../helpers/EventBus";
import errorResponse from "../../../helpers/error";
import { GetDataManagerNew } from "../../../ds";

export default Vue.extend({
  name: "SocialAccount",

  data() {
    const dm = GetDataManagerNew("auth_social");

    return {
      dm: dm,
      error: errorResponse(),
      format: "dd-MM-yyyy · HH:mm",

      lastLoginTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                {{ formattedDate() }}
              </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            methods: {
              formattedDate() {
                const date = new Date(this.data["last_login"]);
                return isValidDate(date) ? formatDate(date, this.format) : "--";
              },
            },
          }),
        };
      },

      dataJoinedTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                {{ formattedDate() }}
              </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            methods: {
              formattedDate() {
                const date = new Date(this.data["date_joined"]);
                return isValidDate(date) ? formatDate(date, this.format) : "--";
              },
            },
          }),
        };
      },

      actionsTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="d-flex justify-content-end">
                <dropdown-menu :items="actions" :select="onActionSelect">
                  <font-awesome-icon icon="ellipsis-h"
                /></dropdown-menu>
              </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            computed: {
              actions() {
                const SOCIAL_ACCOUNT_ACTIONS = [{ id: "DELETE", text: this.$t('user.profile.social.remove') }];
                return SOCIAL_ACCOUNT_ACTIONS;
              },
            },
            methods: {
              onActionSelect: function (args) {
                const { id } = args.item;

                if (id === "DELETE") {
                  if (
                    confirm(this.$t('user.profile.social.remove_confirm')) == true
                  ) {
                    EventBus.$emit("social:remove", this.data);
                  }
                }
              },
            },
          }),
        };
      },


      // lastLoginTemplate: () => {
      //   return {
      //     template: {
      //       extends: TableDate,
      //       propsData: {
      //         field: "last_login",
      //       },
      //     },
      //   };
      // },

      // dataJoinedTemplate: () => {
      //   return {
      //     template: {
      //       extends: TableDate,
      //       propsData: {
      //         field: "date_joined",
      //       },
      //     },
      //   };
      // },

      // actionsTemplate: () => {
      //   return { template: TableActions };
      // },
    };
  },

  provide: {
    grid: [Page, Sort],
  },

  mounted() {
    EventBus.$on("social:remove", this.onRemove);
  },

  beforeDestroy() {
    EventBus.$off("social:remove", this.onRemove);
  },

  methods: {
    oauth2(type) {
      this.$auth.oauth2(type, {});
    },

    removeEmail(id) {
      this.error.reset();
      this.dm
        .remove("", id)
        .then((response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: this.$t('user.profile.social.remove_success'),
            color: "success",
            autohide: true,
          });
          this.$refs.grid.refresh();
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.error.set(body.errors);
        });
    },

    onRemove(item) {
      this.removeEmail(item.id);
    },
  },
});
</script>
